/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

.cc-editor {
    padding-top: 15px;
}

.cc-editor .cue-item-wrapper {
    padding: 3px 0;
}

.cc-editor .cue-item {
    padding: 0 15px 5px 25px;
    border-left: 4px solid rgba(0, 0, 0, 0.3);
    cursor: pointer;
    user-select: none;
}

.cc-editor .cue-item.active {
    border-color: #0099EE;
}

.cc-editor .cue-item.edit {
    background-color: rgba(0, 153, 238, 0.2);
}

.cc-editor .cue-item-text {
    color: #333;
    font-family: Menlo, Monaco, Consolas, "Courier New", monospace;
}

.skin-dark .cc-editor .cue-item-text {
    color: #FFF;
    font-family: Menlo, Monaco, Consolas, "Courier New", monospace;
}


.cc-editor .cue-item-time {
    color: #b1b1b1;
    opacity: 0.6;
}

.cc-editor-player {
    display: flex;
    align-items: center;
    height: 100%;
    background-color: #090909;
}

.cc-editor .cc-editor-header.content-header {
    position: sticky;
    background-color: #1d1d1d;
    top: 0;
    z-index: 2;
}

.cc-editor-header .form-section {
    padding: 5px;
    margin: 10px 0;
}

.cc-editor-header small {
    color: #CCC;
    font-size: 15px;
    font-weight: 300;
}

.cc-editor .resizable-fragment::-webkit-scrollbar {
    width: 5px;
}

.cc-editor .resizable-fragment {
    scrollbar-width: thin;
    scrollbar-color: rgba(255, 255, 255, 0.5) transparent;
}

.cc-editor .resizable-fragment::-webkit-scrollbar-track {
    background: transparent;
}

.cc-editor .resizable-fragment::-webkit-scrollbar-thumb {
    background-color: rgba(255, 255, 255, 0.5);
    border-radius: 6px;
    border: 3px solid transparent;
}

.cc-editor .cue-gap-wrapper {
    position: relative;
    height: 0;
}

.cc-editor .cue-gap-wrapper:last-of-type {
    height: 16px;
}

.cc-editor .cue-gap {
    background-color: transparent;
    border: 0 none;
    color: rgba(177, 177, 177, 0.2);
    position: absolute;
    transform: translate(-22px, -13px);
}

.cc-editor .cue-gap:hover,
.cc-editor .cue-gap.active {
    color: #0099EE;
}

.cc-editor .h1 {
    margin: -6px 0 0 0;
}

.timecode-field {
    display: inline-flex;
    align-items: center;
    color:#b1b1b1;
}

.timecode-field .number-field {
    width: 2.5rem;

    background-color: #161616;
    border: 1px solid #333;
    color: #b1b1b1;
    text-align: center;
    margin: 0 2px;
}

.number-field::-webkit-outer-spin-button,
.number-field::-webkit-inner-spin-button {
    /* display: none; <- Crashes Chrome on hover */
    -webkit-appearance: none;
    margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
}

.number-field[type=number] {
    -moz-appearance:textfield; /* Firefox */
}

.cc-editor .cue-editor.form-section {
    display: flex;
    flex-wrap: wrap;
    border-color: rgba(0, 153, 238, 0.2);
    background-color: rgba(0, 153, 238, 0.05);
}

.nobr-block {
    display: inline-flex;
    white-space: nowrap;
    padding-bottom: 4px;
}

.vertical-divider {
    margin: 1px 10px;
    border-left: 1px solid #3a3a3a;
    display: inline-flex;
}

.nobr-block:last-of-type .vertical-divider {
    display: none;
}

.cue-action-btn {
    margin-right: 4px;
}

.cc-editor .cue-error-message {
    align-items: center;
    display: flex;
    padding-left: 4px;
}

.cc-editor .resizable-fragment {
    overflow-x: hidden !important;
}
